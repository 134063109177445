import {data, setScript} from '@waves/waves-transactions'

export function init(ports) {
    ports.getDataTx.subscribe(async (payload) => {
        console.log(payload);
        const getTx = payload.type === 13 ? setScript : data;
        const tx = await getTx(payload);
        ports.gotDataTxId.send(tx.id);
        console.log(tx);
    })
}