// import { ethers } from "ethers";


// const tokenAddress = '0xd00981105e61274c8a5cd5a88fe7e037d935b513';
// const tokenSymbol = 'TUT';
// const tokenDecimals = 18;
// const tokenImage = 'http://placekitten.com/200/300';

export async function watchAsset(options) {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        return await ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options
                // options: {
                //     address: tokenAddress, // The address that the token is at.
                //     symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                //     decimals: tokenDecimals, // The number of decimals in the token
                //     image: tokenImage, // A string url of the token logo
                // },
            },
        });
}