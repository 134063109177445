import {signCustomData} from "@vires.finance/dapp";
import { wavesAddress2eth } from '@waves/node-api-js';

export function clearCredentials(address) {
    localStorage.removeItem("gateway_accessToken_" + address);
    localStorage.removeItem("gateway_refreshToken_" + address);
    localStorage.removeItem("gateway_expires_in_" + address);
}

export async function getCredentials(address) {
    const expires = localStorage.getItem("gateway_expires_in_" + address);
    if (!expires) return null;

    const gateway_expires_in = new Date(expires);
    if (new Date() > gateway_expires_in) {
        console.log("gw token expired")
        const refresh = localStorage.getItem("gateway_refreshToken_" + address);
        clearCredentials(address);

        await refreshToken(refresh, address)
    }

    const accessToken = localStorage.getItem("gateway_accessToken_" + address);

    if (!accessToken) return null;

    return accessToken;
}

async function saveCredentials(res, address) {
    const json = await res.json();

    // console.log(json);
    localStorage.setItem("gateway_accessToken_" + address, json.access_token);
    localStorage.setItem("gateway_refreshToken_" + address, json.refresh_token);
    const now = new Date();
    const expire = new Date(now.getTime() + json.expires_in * 1000);
    localStorage.setItem("gateway_expires_in_" + address, expire.getTime());

}


export async function authGateway(chainId, loginResult) {
    try {
        const res = await auth(chainId, loginResult);

        if (res.ok) {
            await saveCredentials(res, loginResult.address);
        } else {
            const err = await res.json();
            console.error(err)
        }
    } catch (e) {
        console.error(e)
    }
}

const client_id = "waves.exchange";


async function auth(chain_code, loginResult) {

    const seconds = Math.round((Date.now() + 1000 * 60 * 60 * 24 * 7) / 1000); // Токен на неделю
    const message = `${chain_code}:${client_id}:${seconds}`;
    const signature = await signCustomData(loginResult.keeper || loginResult.signer, message)

    const username = loginResult.publicKey ?? wavesAddress2eth(loginResult.address);
    


    return fetch('https://api.waves.exchange/v1/oauth2/token', {
        method: 'POST',
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        body: [
            "grant_type=password",
            "scope=general",
            "username=" + encodeURIComponent(username),
            "password=" + encodeURIComponent(`${seconds}:${signature}`),
            `client_id=${client_id}`
        ].join('&')
    });

}

async function refreshToken(refreshToken, address) {
    const res = await fetch('https://api.waves.exchange/v1/oauth2/token', {
        method: 'POST',
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        body: [
            "grant_type=refresh_token",
            "scope=general",
            `refresh_token=${refreshToken}`,
            `client_id=${client_id}`
        ].join('&')
    });

    if (res.ok) {
        await saveCredentials(res, address)
    } else {
        // console.log(res);
        const err = await res.json();
        console.error(err)
    }
}