import { watchAsset } from "./addToMetaMask";


export function init(ports) {
    ports.watchAsset.subscribe(async (options) => {
        try {
            const added = await watchAsset(options);
            if (!added) {
                console.log("Asset was not added")
            }
        }
        catch(e) {
            console.error(e)
        }
    })
}
