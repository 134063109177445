import {verifyAddress} from "@waves/ts-lib-crypto"

export function init(ports, chainId) {
    ports.verifyWavesAddress.subscribe(verityWavesAddress)

    function verityWavesAddress(address) {
        verifyAddress(address, {chainId})
            ? ports.wavesAddressCorrect.send(address)
            : ports.wavesAddressIncorrect.send(address)
    }
}