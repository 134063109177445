import { ethers } from "ethers";


export async function ethereumWalletInstalled() {
    // todo: 
    //  check ethereum.isConnected() to the current chain
    // ethereum._metamask.isUnlocked(): Promise<boolean>;
    return !!window.ethereum
}

const chains =
    {
        "ETH": { chainId: "0x1" },
        "BSC":
            {
                chainId: "0x38",
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                chainName: "Binance Smart Chain",
                nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB", // 2-6 characters long
                    decimals: 18
                },
                blockExplorerUrls: ["https://bscscan.com"]
            },
        "POLYGON":
            {
                chainId: "0x89",
                rpcUrls: ["https://polygon-rpc.com"],
                chainName: "Polygon",
                nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC", // 2-6 characters long
                    decimals: 18
                },
                blockExplorerUrls: ["https://polygonscan.com"]
            }
    }

async function switchNetwork(platform) {
    const chain = chains[platform];
    try {
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [ {chainId: chain.chainId} ],
        });
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [ chain ],
                });
            } catch (addError) {
                console.error(addError)
                throw addError
            }
        }
        console.error(switchError)
        throw switchError
    }
}

export async function getBalance(assets, currency) {

    await switchNetwork(currency.platform);
    await ethereum.enable(); // todo: obsolete

    const provider = new ethers.providers.Web3Provider(ethereum);
    const accounts = await provider.listAccounts();
    if (accounts.length === 0) {
       throw "MetaMask account list is empty"
    }

    const address = accounts[0];

    if (currency.id === "ETH") {
        const balance = await provider.getBalance(address)
        return parseInt(balance.toString()); // todo: is it correct way to convert bigNumber?
    }

    const tokenAddress = assets.find(a => a.id === currency.assetId)?.platforms[currency.platform];
    // console.log("sc address: " + tokenAddress)
    const contract = new ethers.Contract(tokenAddress, erc20Abi, provider);

    const balance = await contract.balanceOf(address);
    // console.log(balance)
    return parseInt(balance.toString())
}


export async function topup(assets, platform, address, token, decimals, amount) {
    await switchNetwork(platform); 
    await ethereum.enable(); // todo: obsolete
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const tx = await getTx(assets, provider, platform, address, token, decimals, amount);
    await provider.waitForTransaction(tx.hash)
    return tx.hash
}

async function getTx(assets, provider, platform, address, token, decimals, amount) {
    const signer = provider.getSigner()

    if (token === "ETH") {
        return await signer.sendTransaction({
            to: address,
            value: ethers.utils.parseEther(amount.toString())
        })
    }


    const tokenAddress = assets.find(a => a.name === token)?.platforms[platform];
    if (!tokenAddress) {
        console.error(token + " SC address not found");
        return;
    }
    // console.log(token + " SC address: " + tokenAddress);

    const contract = new ethers.Contract(tokenAddress, erc20Abi, provider);

    // The Token Contract is currently connected to the Provider,
    // which is read-only. You need to connect to a Signer, so
    // that you can pay to send state-changing transactions.
    const tokenWithSigner = contract.connect(signer)

    const tokenAmount = ethers.utils.parseUnits(amount.toString(), decimals);

    return await tokenWithSigner.transfer(address, tokenAmount);

}


const erc20Abi = [
    // Some details about the token
    // "function name() view returns (string)",
    // "function symbol() view returns (string)",

    // Get the account balance
    "function balanceOf(address) view returns (uint)",

    // Send some of your tokens to someone else
    "function transfer(address to, uint amount)",

    // An event triggered whenever anyone transfers to someone else
    // "event Transfer(address indexed from, address indexed to, uint amount)",

    "function approve(address spender, uint256 amount) external returns (bool)",

    "function decimals() returns (uint8)"
];